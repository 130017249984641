









































































































































































































































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import {
  GetPalmTags,
  EditPalmTags,
  GetPalmDetail,
  GetPalmDetailZhenci,
  EditPalmDetail,
} from "@/request/research";
import PicCanvas from "./components/pic-canvas.vue";
import Fenge from "./components/fenge.vue";
import Jianyi from "./components/jianyi.vue";
import Palm from "./components/palm.vue";
@Component({
  components: {
    PicCanvas,
    Fenge,
    Jianyi,
    Palm,
  },
})
export default class IdrBiaozhu extends Vue {
  private type: any = ""; // teacher 教师标注
  private projectId: any = "";
  private id: any = "";
  private options: any = {};
  private currentPicSrc: any = {};
  private pics: any = {
    left: [],
    right: [],
  };
  private detail: any = {
    l_palm_image: [],
    r_palm_image: [],
    专家建议: {},
    轨迹: {},
  };
  private data: any[] = [];
  private model: any = "";
  private picType: any = "origin";
  private cPart: any = "手掌";
  private ifShowBidui: boolean = false;
  private loaded: boolean = false;
  private ifOpenFenge: boolean = false;
  private kuankuanbiHelp: boolean = false;
  private labels: any = [];
  private ifShowAddLabel: boolean = false;
  private tempLabels: any[] = [];
  private get picCanvas() {
    return this.$refs.picCanvas as any;
  }
  @Watch("$route.query", { immediate: true, deep: true })
  private queryChange() {
    this.id = this.$route.query.id;
    this.type = this.$route.query.type;
    this.projectId = this.$route.query.project_id;
    if (this.$route.query.pType) {
      this.cPart = this.$route.query.pType;
    }
    if (this.type == "teacher") {
      this.$store.commit("updateIfCompose", true);
    } else {
      this.$store.commit("updateIfCompose", false);
    }
    this.fetchDetail().then(() => {
      this.fetchPatientAllTongue();
    });
  }
  private get ifCompose() {
    return this.$store.state.ifCompose;
  }
  private dahui() {
    this.$confirm(
      "您确定需要打回么?打回后须等标注员再次提交后才能审核,并且当前您的审核内容将会清空。",
      "审核完成",
      {
        customClass: "commonConfirm",
      }
    ).then(() => {
      this.save("打回修改").then((res: any) => {
        this.$router.back();
      });
    });
  }
  private goEdit() {
    this.$store.commit("updateIfCompose", true);
  }
  private finishBiaozhu() {
    this.$confirm(
      "您确定标注完成么？确定提交后将不能再修改，后续会由上级对您的标注报告进行审核评分，审核完成后，可点击【查看报告】查看评分！",
      "标注完成",
      {
        customClass: "commonConfirm",
      }
    ).then(() => {
      this.save("标注完成");
    });
  }
  private finishShenhe() {
    this.$confirm(
      "您确定已经审核完成了？确定后，系统会出具评估报告，可点击【查看报告】查看详情。",
      "审核完成",
      {
        customClass: "commonConfirm",
      }
    ).then(() => {
      this.save("审核完成");
    });
  }
  private update(e: any) {
    this.detail = e;
    this.$forceUpdate();
  }
  /**
   * @description 跳转智能比对
   */
  private jumpBidui() {
    // localStorage.setItem("selectedTongues", JSON.stringify([this.detail]));
    // this.$router.push("/main/research/tongue/bidui-list");
  }
  /**
   * @description 保存
   */
  private save(type?: any) {
    return new Promise((resolve, reject) => {
      const params: any = JSON.parse(JSON.stringify(this.detail));
      if (type) {
        params.is_mark = type;
      }
      EditPalmDetail(this, params)
        .then((data: any) => {
          this.$message.success(type ? type + "完成" : "保存成功");
          this.$store.commit("updateIfCompose", false);
          this.fetchDetail();
          resolve(true);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  /**
   * @description 获得该患者的全部图片
   */
  private fetchPatientAllTongue() {
    const params: any = {
      params: {
        patient_id: this.detail.patient_id,
        project_id: this.projectId,
      },
    };
    GetPalmDetailZhenci(this, params).then((data: any) => {
      this.data = data;
    });
  }
  /**
   * @description 获得详情
   */
  private fetchDetail() {
    this.loaded = false;
    return new Promise((resolve, reject) => {
      const params: any = {
        params: {
          palm_id: this.id,
        },
      };
      if (this.type == "teacher") {
        params.params.is_mark = true;
      }
      GetPalmDetail(this, params)
        .then((data: any) => {
          if (!data["轨迹"]) {
            data["轨迹"] = {};
          }
          this.detail = data;
          this.pics = {
            left: [],
            right: [],
          };
          if (this.detail.l_palm_image && this.detail.l_palm_image.length > 0) {
            this.detail.l_palm_image.forEach((ele: any, index: any) => {
              const obj = {
                type: "left",
                index: index,
                url: ele,
              };
              this.pics.left.push(obj);
            });
          }
          if (this.detail.r_palm_image && this.detail.r_palm_image.length > 0) {
            this.detail.r_palm_image.forEach((ele: any, index: any) => {
              const obj = {
                type: "right",
                index: index,
                url: ele,
              };
              this.pics.right.push(obj);
            });
          }
          if (this.pics.left.length > 0) {
            this.currentPicSrc = this.pics.left[0];
          } else {
            this.currentPicSrc = this.pics.right[0];
          }
          this.loaded = true;
          // 模拟数据
          resolve(true);
        })
        .catch(() => {
          reject();
        });
    });
  }
  /**
   * @description 返回列表
   */
  private backList() {
    this.$router.push("/main/research/hand/list");
  }
  /**
   * @description 跳转
   */
  private jumpPreview() {
    if (!this.ifCompose) {
      this.$router.push({
        path: "/main/research/hand/report",
        query: {
          id: this.detail.palm_id,
        },
      });
      return;
    }
    const params: any = JSON.parse(JSON.stringify(this.detail));
    EditPalmDetail(this, params).then((data: any) => {
      this.$router.push({
        path: "/main/research/hand/report",
        query: {
          id: this.detail.palm_id,
        },
      });
    });
  }
  /**
   * @description 切换同一个患者的其他图
   */
  private changeZhenci(d: any) {
    this.$router.push({
      path: "/main/research/hand/biaozhu",
      query: {
        id: d.palm_id,
        project_id: this.projectId,
      },
    });
  }
  /**
   * @description 切换展示的图片
   */
  private changeCurrentPic(src: any, model: any) {
    this.currentPicSrc = src;
    this.picType = model;
  }
  // 图像标注
  /**
   * @description 局部舌色
   */
  private jubushese() {
    this.model = "局部舌色";
    this.cPart = "望舌体";
    this.$nextTick().then(() => {
      (this.$refs.sheti as any).jubusheseScrollToView();
    });
    this.picCanvas.jubushese();
  }
  /**
   * @description 删除局部舌色
   */
  private deleteJubushese() {
    this.picCanvas.deleteJubushese();
  }
  /**
   * @description 局部苔色
   */
  private jubutaise() {
    this.model = "局部苔色";
    this.cPart = "望舌苔";
    this.$nextTick().then(() => {
      (this.$refs.shetai as any).jubutaiseScrollToView();
    });
    this.picCanvas.jubutaise();
  }
  /**
   * @description 删除局部苔色
   */
  private deleteJubutaise() {
    this.picCanvas.deleteJubutaise();
  }

  /**
   * @description 舌上红点
   */
  private sheshanghongdian() {
    this.model = "舌上红点";
    this.cPart = "望舌体";
    // this.$nextTick().then(() => {
    //   (this.$refs.sheti as any).tezhengbiaozhuScrollToView();
    // });
    this.picCanvas.sheshanghongdian();
  }
  /**
   * @description 删除舌上红点
   */
  private deleteSheshanghongdian() {
    this.picCanvas.deleteSheshanghongdian();
  }
  /**
   * @description 瘀斑
   */
  private yuban() {
    this.model = "瘀斑";
    this.picCanvas.yuban();
  }
  /**
   * @description 删除某个瘀斑
   */
  private deleteYuban(index: any) {
    this.picCanvas.deleteYuban(index);
  }
  /**
   * @description 齿痕
   */
  private chihen() {
    this.model = "齿痕";
    this.picCanvas.chihen();
  }
  /**
   * @description 删除某个齿痕
   */
  private deleteChihen(index: any) {
    this.picCanvas.deleteChihen(index);
  }
  /**
   * @description 裂纹
   */
  private liewen() {
    this.model = "裂纹";
    this.picCanvas.liewen();
  }
  /**
   * @description 删除某个裂纹
   */
  private deleteLiewen(index: any) {
    this.picCanvas.deleteLiewen(index);
  }
  /**
   * @description 胖瘦
   */
  private pangshou() {
    this.model = "胖瘦";
    // this.cPart = "望舌体";
    if (!this.detail["轨迹"]["胖瘦"]) {
      this.detail["轨迹"]["胖瘦"] = {
        嘴角间距: {},
        舌间距: {},
      };
    }
    // this.$nextTick().then(() => {
    //   (this.$refs.sheti as any).tezhengbiaozhuScrollToView();
    // });
    this.picCanvas.pangshou();
    if (this.kuankuanbiHelp === false && this.picType === "origin") {
      this.$confirm(
        "宽宽比是指嘴角间距和舌两边距离的比值，您可以先在嘴角画一条线，再在舌两边画一条线，系统就会自动得出宽宽比",
        {
          showCancelButton: false,
        }
      );
    }
    this.kuankuanbiHelp = true;
  }
  /**
   * @description 删除胖瘦的某条线
   */
  private deletePangshou(part: any) {
    this.picCanvas.deletePangshou(part);
  }
  /**
   * @description 苔质
   */
  private taizhi() {
    this.model = "苔质";
    this.picCanvas.taizhi();
  }
  /**
   * @description 删除某个苔质
   */
  private deleteTaizhi(index: any) {
    this.picCanvas.deleteTaizhi(index);
  }
  /**
   * @description 剥落
   */
  private boluo() {
    this.model = "剥落";
    this.picCanvas.boluo();
  }
  /**
   * @description 删除某个剥落
   */
  private deleteBoluo(index: any) {
    this.picCanvas.deleteBoluo(index);
  }
  /**
   * @description 局部舌苔
   */
  private jubushetai() {
    this.model = "舌苔局部放大图";
    this.picCanvas.jubushetai();
  }
  /**
   * @description 删除某个舌苔局部放大图
   */
  private deleteJubushetai(index: any) {
    this.picCanvas.deleteJubushetai(index);
  }
  /**
   * @description 点刺
   */
  private dianci() {
    this.model = "点刺";
    this.picCanvas.dianci();
  }
  /**
   * @description 删除某个点刺
   */
  private deleteDianci(index: any) {
    this.picCanvas.deleteDianci(index);
  }
  /**
   * @description 舌态
   */
  private shetai() {
    this.model = "舌态";
    this.picCanvas.shetai();
  }
  /**
   * @description 删除某个舌态
   */
  private deleteShetai(index: any) {
    this.picCanvas.deleteShetai(index);
  }
  /**
   * @description 瘀点
   */
  private yudian() {
    this.model = "瘀点";
    this.picCanvas.yudian();
  }
  /**
   * @description 删除某个瘀点
   */
  private deleteYudian(index: any) {
    this.picCanvas.deleteYudian(index);
  }
  /**
   * @description 打开分割弹窗
   */
  private fenge() {
    this.ifOpenFenge = true;
  }
  // 图像操作
  /**
   * @description 移动
   */
  private yidong() {
    this.model = "移动";
    this.picCanvas.yidong();
  }
  /**
   * @description 缩放
   */
  private suofang() {
    this.model = "缩放";
    this.picCanvas.suofang();
  }
  /**
   * @description 放大镜
   */
  private fangdajing() {
    this.model = "放大镜";
    this.picCanvas.fangdajing();
    this.cPart = "望舌苔";
    this.$nextTick().then(() => {
      (this.$refs.shetai as any).jubushetaiScrollToView();
    });
  }
  /**
   * @description 旋转
   */
  private xuanzhuan() {
    this.model = "旋转";
    this.picCanvas.xuanzhuan();
  }
  /**
   * @description 截屏
   */
  private jieping() {
    this.model = "截屏";
    this.picCanvas.jieping();
  }
  /**
   * @description 还原
   */
  private huanyuan() {
    this.model = "还原";
    this.picCanvas.huanyuan();
  }
  /**
   * @description 改变model
   */
  private modelChange(model: any) {
    this.model = model;
  }
  /**
   * @description 切换显示的部分
   */
  private changePart(part: any) {
    this.cPart = part;
  }
  /**
   * @description 获得可选的标签列表
   */
  private fetchBiaoqianList() {
    return new Promise((resolve, reject) => {
      GetPalmTags(this)
        .then((data: any) => {
          this.labels = data;
          resolve(true);
        })
        .catch(() => {
          reject();
        });
    });
  }
  /**
   * 当前标签是否被选中
   */
  private getHas(name: any) {
    let result = false;
    if (this.detail["labels"] && this.detail["labels"].length > 0) {
      this.detail["labels"].forEach((ele: any) => {
        if (ele["tag_name"] === name) {
          result = true;
        }
      });
    }
    return result;
  }
  /**
   * @description 删除当前舌图标签
   */
  private delOpt(i: any) {
    this.detail["labels"].splice(i, 1);
    this.$forceUpdate();
  }
  /**
   * @description 选择舌图标签
   */
  private selOne(item: any) {
    if (this.getHas(item["名称"])) {
      return;
    }
    if (!this.detail["labels"]) {
      this.detail["labels"] = [];
    }
    this.detail["labels"].push(item);
    this.$forceUpdate();
  }
  /**
   * @description 打开添加标签弹窗
   */
  private openAddLabel() {
    this.tempLabels = JSON.parse(JSON.stringify(this.labels));
    if (this.tempLabels.length === 0) {
      this.$set(this.tempLabels, "0", { tag_name: "" });
    }
    this.ifShowAddLabel = true;
  }
  /**
   * @description 关闭弹窗
   */
  private closeAddLabel() {
    this.ifShowAddLabel = false;
  }
  /**
   * @description 刷新页面
   */
  private refresh() {
    this.fetchBiaoqianList().then(() => {
      this.filterSelectedLables();
    });
  }
  /**
   * @description 根据新的标签列表，筛选已选标签
   */
  private filterSelectedLables() {
    this.detail["labels"].forEach((item: any, index: any) => {
      this.labels.forEach((i: any) => {
        if (i.tag_id === item.tag_id) {
          this.detail["labels"].splice(index, 1);
        }
      });
    });
  }
  /**
   * @description 添加临时标签
   */
  private addLabel() {
    this.tempLabels.unshift({ tag_name: "" });
  }
  /**
   * @description 删除一个标签
   */
  private deleteLabel(index: any) {
    if (this.tempLabels.length === 1) {
      this.tempLabels = [{ tag_name: "" }];
    } else {
      this.tempLabels.splice(index, 1);
    }
  }
  /**
   * @description 保存对标签的修改
   */
  private saveAddedLabel() {
    const params: any = {
      tags: this.tempLabels,
    };
    EditPalmTags(this, params).then(() => {
      this.$message.success("修改成功");
      this.ifShowAddLabel = false;
      this.tempLabels = [];
    });
  }
  /**
   * @description 初始化
   */
  private mounted() {
    this.fetchBiaoqianList();
  }
}
